
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "ReviewMentoringProgram",
  props: {
    isPublished: Boolean,
    mentee: Object,
    mentor: Object,
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      iAgree: false,
      iConfirm: false,
      submitBtnLoading: false,
      overview: {} as any,
      infoData: [
        {
          name: "start_date",
          label: (this as any).$t("mentroingprogram.programItemsStartDate"),
          icon: "calendar"
        },
        {
          name: "is_free_library",
          label: (this as any).$t("mentroingprogram.infoDataFreeLibrary"),
          icon: "books"
        },
        {
          name: "duration",
          label: (this as any).$t("mentroingprogram.infoDataDuration"),
          icon: "time-circle"
        },
        {
          name: "no_of_milestones",
          label: (this as any).$t(
            "mentroingprogram.infoDataNumberOfMilestones"
          ),
          icon: "milestone"
        },
        {
          name: "is_certificate",
          label: (this as any).$t("mentroingprogram.infoDataCertificates"),
          icon: "education"
        },
        {
          name: "price",
          label: (this as any).$t("mentroingprogram.infoDataProgramPrice"),
          icon: "paid"
        }
      ],
      information: [
        {
          icon: "edit",
          text: (this as any).$t("mentroingprogram.informationEdit")
        },
        {
          icon: "milestone",
          text: (this as any).$t("mentroingprogram.informationMilestone")
        },
        {
          icon: "paid",
          text: (this as any).$t("mentroingprogram.informationPaid")
        },
        {
          icon: "close",
          text: (this as any).$t("mentroingprogram.informationClose")
        }
      ],
      earningsDetails: {
        price: {
          label: (this as any).$t(
            "mentroingprogram.earningsDetailsProgramPrice"
          ),
          icon: "program-price",
          price: true
        },
        mentorice_fee: {
          label: (this as any).$t(
            "mentroingprogram.earningsDetailsMentoriceFee"
          ),
          icon: "mentorice",
          percent: true
        },
        donations: {
          label: (this as any).$t(
            "mentroingprogram.earningsDetailsScienceDonations"
          ),
          icon: "donations",
          percent: true
        }
      } as any
    };
  },
  computed: {
    ...mapGetters({
      platformCurrency: "helper/getDefaultPlatformCurrency"
    })
  },
  created: function(): void {
    this.$store.dispatch("helper/GET_DEFAULT_PLATFORM_CURRENCY");
  },
  mounted() {
    this.$store
      .dispatch("program/FINANCIAL_OVERVIEW", {
        program_id: this.$route.params?.id,
        price: this.data.price,
        duration: this.data.duration
      })
      .then(data => {
        this.overview = data;
      });
  },
  methods: {
    getData(item: any) {
      if (item === "is_free_library" || item === "is_certificate") {
        return this.data[item] ? "Yes" : "No";
      }
      if (item in this.data) {
        return this.data[item];
      }
    },
    onSubmit() {
      this.submitBtnLoading = true;

      this.isPublished ? this.update() : this.create();
    },
    create() {
      this.$store
        .dispatch("program/CREATE_PROGRAM", this.data)
        .then(id => {
          this.submitBtnLoading = false;
          (this as any).$alertify.success(
            (this as any).$t("mentroingprogram.programCreateSuccessMessage")
          );
          this.$router.push(`/mentoring-programs/${id}`);
        })
        .catch(error => {
          this.submitBtnLoading = false;
          if (error.response.status === 422) {
            this.$emit("errors", error.response.data.errors);
          }
        });
    },
    update() {
      this.$store
        .dispatch("program/UPDATE_PROGRAM", {
          id: this.$route.params.id,
          formData: this.data
        })
        .then(id => {
          this.submitBtnLoading = false;
          (this as any).$alertify.success(
            (this as any).$t("mentroingprogram.programUpdateSuccessMessage")
          );
          this.$router.push(`/mentoring-programs/${id}`);
        })
        .catch(error => {
          this.submitBtnLoading = false;
          if (error.response.status === 422) {
            this.$emit("errors", error.response.data.errors);
          }
        });
    },
    editProgram() {
      this.$emit("edit-program");
    }
  }
});
