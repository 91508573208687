
import Vue from "vue";
import { mapGetters } from "vuex";
import ReviewMentoringProgram from "@/components/MentoringPlan/ReviewMentoringProgram.vue";

export default Vue.extend({
  name: "ReviewMentoringProgramPage",
  components: {
    ReviewMentoringProgram
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile"
    })
  }
});
